<template>
  <v-app-bar app color="white" clipped-left fixed v-if="show">
    <div class="d-flex align-center" v-if="selectedCompanyId">
      <img class="hidden-sm-and-down" style="cursor:pointer" id="logo-src" @click="goHome()" v-bind:src="logoData" />
    </div>

    <v-autocomplete
      :items="searchMatches"
      filled
      dense
      rounded
      auto-select-first
      prepend-inner-icon="mdi-magnify"
      class="mt-4 searchbar"
      :loading="loading"
      :search-input.sync="searchQuery"
      hide-no-data
      placeholder="Search all documents..."
      persistent-placeholder
      :filter="() => true"
      @change="onSelect"
    >
      <template v-slot:item="data">
        <v-list-item-content class="match-text">
          <v-list-item-title>
            <v-icon v-show="data.item.isSearch">mdi-magnify</v-icon>
            <v-icon v-show="!data.item.isSearch">mdi-file-document</v-icon>
            {{ data.item.title }}
          </v-list-item-title>
          <v-list-item-subtitle class="ml-4 pl-4" v-html="data.item.matchText"></v-list-item-subtitle>
        </v-list-item-content>
      </template>
    </v-autocomplete>

    <v-spacer></v-spacer>
    <h3 class="text-h6 ma-3"> {{ getCompanyName() }} </h3>
    <v-menu offset-y>
      <template v-slot:activator="{ on }">
        <div class="account-menu">
          <v-btn icon large v-on="on" v-if="!$auth.isAuthenticated && !$auth.loading">
            <v-icon>account_circle</v-icon>
          </v-btn>
          <v-avatar
            v-on="on"
            v-if="$auth.isAuthenticated && !$auth.loading"
            data-cy="profile"
            color="gray"
            v-bind:class="{ 'global-user': isGlobalUser }"
            size="48"
          >
            <v-img :src="$auth.user.picture"></v-img>
          </v-avatar>
        </div>
      </template>
      <v-list>
        <v-list-item @click="showChangeCompanyDialog = true" v-if="$auth.isAuthenticated">
            <v-icon>mdi-swap-horizontal</v-icon>
            <v-list-item-title class="pl-2">Change Company</v-list-item-title>
        </v-list-item>
        <v-list-item @click="changePass" v-if="$auth.isAuthenticated">
          <v-icon>lock</v-icon>
          <v-list-item-title class="pl-2">Reset Password</v-list-item-title>
        </v-list-item>
        <v-list-item @click="gotoNotification" v-if="$auth.isAuthenticated">
          <v-icon>mdi-bell-outline</v-icon>
          <v-list-item-title class="pl-2">Notifications({{unreadNotifications}})</v-list-item-title>
        </v-list-item>
        <v-list-item @click="naviateToUserProile" v-if="$auth.isAuthenticated">
          <v-icon>mdi-account-details</v-icon>
          <v-list-item-title class="pl-2">Edit my Profile</v-list-item-title>
        </v-list-item>
        <v-list-item data-cy="logout" @click="logout" v-if="$auth.isAuthenticated">
          <v-icon>input</v-icon>
          <v-list-item-title class="pl-2">Logout</v-list-item-title>
        </v-list-item>
      </v-list>
    </v-menu>
      
    <ChangeCompanyDialog 
      :show="showChangeCompanyDialog"
      :cancel="() => (showChangeCompanyDialog = false)"
    ></ChangeCompanyDialog>
  </v-app-bar>
</template>

<script>
import { mapGetters } from "vuex";
import localStorageService from "@/services/localStorageService.js";
import userService from "@/services/userService";
import auth0Config from "@/auth0Config";
import { URL } from "@/env";
import filterService from "@/services/filterService.js";
import typeaheadService from "@/services/typeaheadService";
import ChangeCompanyDialog from "@/dialogs/ChangeCompanyDialog.vue";

export default {
  props: {
    show: Boolean,
  },

  components: {
    ChangeCompanyDialog,
  },

  data() {
    return {
      searchMatches: [],
      searchQuery: "",
      highlight: filterService.highlight,
      loading: false, 
      showChangeCompanyDialog: false,
    };
  },

  watch: {
    async searchQuery(query) {
      if (!query) return;

      this.loading = true;
      let typeaheadResults = await typeaheadService.search(query);
      this.searchMatches = [{ title: `Search for "${query}"`, isSearch: true }, ...typeaheadResults];

      this.loading = false;

      return query;
    },
  },

  methods: {
    goHome() {
      this.$router.push({ name: "Home" });
    },

    getCompanyName() {
      var company = this.companies.find(x => x.id == this.selectedCompanyId);
      
      if (company) return company.name
    },

    async logout() {
      localStorageService.clear();
      await this.$auth.logout();
      const encodedUrl = encodeURIComponent(URL);
      window.location.href = `https://${auth0Config.domain}/v2/logout?client_id=${auth0Config.clientId}&returnTo=${encodedUrl}`;
    },
    async changePass() {
      await userService.resetPassword();
      this.$root.$snackbar.message("Check your inbox to reset your password");
    },
    gotoNotification() {
      this.$router.push({ name: "Notifications" });
    },
    naviateToUserProile() {
      this.$router.push({ name: "Profile" });
    },
    onSelect(item) {
      if (item.isSearch) return this.onSearch();

      this.searchMatches = [];
      this.searchQuery = "";
      this.$router.push({ name: "Document", params: { id: `${item.id}` } });
    },

    onSearch() {
      this.searchMatches = [];
      this.$router.push(`/search?q=${encodeURIComponent(this.searchQuery)}`);
    },
  },

  computed: {
    ...mapGetters("auth0Store", ["companies", "isGlobalUser", "selectedCompanyId"]),
    ...mapGetters("logoStore", ["logoData"]),
    ...mapGetters("userProfile", ["unreadNotifications"]),
  },
};
</script>
<style>
.match-text em {
  background: #eeed8b;
  font-style: normal;
}

.searchbar {
  padding-top: 12px !important;
}
</style>
