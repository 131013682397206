<template>
  <div>
    <v-btn :small="small" @click.stop="connect(party)" v-show="partyStatusService.isOnNetwork(party)" color="primary">
      Connect</v-btn
    >
    <v-btn :small="small" v-show="partyStatusService.isNotOnNetwork(party)" @click.stop="invite(party)"> Invite</v-btn>
    <span v-show="partyStatusService.isConnected(party)">
      <v-chip class="pa-4" color="green" text-color="white">
        <v-icon left> mdi-handshake-outline </v-icon>
        Connected
      </v-chip>
    </span>
    <span v-show="partyStatusService.IsConnecting(party)">
      <v-tooltip bottom>
        <template v-slot:activator="{ on, attrs }">
          <v-chip class="pa-4" v-bind="attrs" v-on="on">
            <v-icon left> mdi-handshake-outline </v-icon>
            Waiting for other party to accept
          </v-chip>
        </template>
        <span>The connection will be finalized when the other party accepts the invite</span>
      </v-tooltip>
    </span>
    <span v-show="partyStatusService.IsInviteSent(party)" @mouseover="isHover = true" @mouseleave="isHover = false">
      <v-chip class="pa-4" v-if="!isHover">
        <v-icon left> mdi-email-open-outline </v-icon>
        Invite sent
      </v-chip>
      <v-btn :small="small" v-if="isHover" @click.stop="resendInvite(party)"> Resend Invite</v-btn>
    </span>
  </div>
</template>
<script>
import partyService from "@/services/partyService.js";
import partyStatusService from "@/services/partyStatusService.js";
import validationService from "@/services/validationService.js";
import invitationService from "@/services/invitationService";

const requiredRule = [v => (!!v && validationService.isvalidEmail(v)) || "Must be a valid e-mail"];

export default {
  props: {
    party: Object,
    small: Boolean,
    callback: Function, // Called when action is complete (invite or connect)
  },
  data() {
    return {
      partyStatusService,
      isHover: false,
    };
  },
  methods: {
    async showConfirmDialog() {
      let answer = await this.$root.$confirmDialog.open(
        "Accept",
        "By clicking continue, you agree that you have done any applicable due diligence specific to your company policies relevant to accepting documents from outside parties",
        "Continue"
      );

      return answer;
    },
    async resendInvite(party) {
      let email = await this.$root.$inputDialog.open("Do you want to change the email?", "E-mail", requiredRule, party.emails[0]);
      if (email !== party.emails[0]) {
        let fullParty = await partyService.get(party.id);
        fullParty.emails.unshift(email);
        await partyService.update(fullParty);
        party.emailsAsString = email;
      }
      await invitationService.resend(party.id, email);
      party.isInviteSent = true;
    },
    async invite(party) {
      try {
        let fullParty = await partyService.get(party.id);
        var emailAddress = fullParty.emails[0];
        var email = await this.$root.$inputDialog.open("Where should we send the invite?", "E-mail", requiredRule, emailAddress);
        if (!email) {
          return;
        }
        if(fullParty.emails[0] !== email){
          fullParty.emails = [email];
          await partyService.update(fullParty);            
        }

        party.emailsAsString = email;

        let answer = await this.showConfirmDialog();

        if (!answer) return;

        party.isInviteSent = true;
        await partyService.invite(party.id);
      } catch (err) {
        this.$root.$snackbar.exception(err);
        party.isInviteSent = false;
      }
      if (this.callback) this.callback();
    },

    async connect(party) {
      let answer = await this.showConfirmDialog();

      if (answer) {
        party.isInviteSent = true;
        await partyService.connect(party.id);
        if (this.callback) this.callback();
      }
    },
  },
};
</script>
