<template>
  <v-dialog v-model="show" :max-width="800" :persistent="true" :fullscreen="isMobile">
    <v-card id="dialog-body">
      <v-card-title class="pb-0">
        <v-spacer />
        <v-icon left>mdi-swap-horizontal</v-icon>
        Select Company to Navigate to
        <v-spacer />
        <v-btn icon @click="cancel">
            <v-icon>mdi-close</v-icon>
        </v-btn>
      </v-card-title>
      <div class="ml-4 mb-4">
        <v-text-field 
          v-model="searchQuery"
          prepend-icon="mdi-magnify"
          :label="searchLabel"
          single-line
          hide-details
          class="mr-4"
        ></v-text-field>
      </div>
      <div id="dialog-table">
        <v-data-table
          disable-pagination
          :hide-default-header="true"
          :hide-default-footer="true"
          :must-sort="true"
          :no-data-text="''"
          :headers="headers"
          :items="allCompaniesExceptCurrent"
          :search="searchQuery"
          @click:row="item => selectCompany(item)"
        >
          <template v-slot:item.icon="{}">
            <v-icon>mdi-warehouse</v-icon>
          </template>
          <template v-slot:item.name="{ item }">
            <div v-html="highlight(item.name, searchQuery)"></div>
          </template>
        </v-data-table>
      </div>
    </v-card>
  </v-dialog>
</template>

<script>
import filterService from "@/services/filterService.js";
import responsiveService from "@/services/responsiveService";
import {mapGetters} from "vuex";
import Auth0Mixin from "../views/Auth0Mixin";

export default {
  name: "ChangeCompany",
  mixins: [Auth0Mixin],
  props: {
    show: Boolean,
    cancel: Function,
  },
  data() {
    return {
      headers: [
        { text: "", value: "icon", width: 40 },
        { text: "Name", value: "name" },
      ],
      searchQuery: "",
      searchLabel: "Company Name",
      allCompaniesExceptCurrent: [],
      highlight: filterService.highlight,
    };
  },

  computed: {
    ...mapGetters("auth0Store", ["companies", "selectedCompanyId"]),
    isMobile() {
      return responsiveService.isMobile(this.$vuetify.breakpoint);
    },
  },

  methods: {
    async init() {
        this.allCompaniesExceptCurrent = this.companies.filter(x => x.id != this.selectedCompanyId);
    },
    selectCompany(company) {
      if (company.isCloud) {
        const url = company.url;
        window.location.href = `${url}/repository`;
      } else {
        this.$store.dispatch("auth0Store/updateCompanyId", { selectedCompanyId: company.id });
        window.location.href = "/";
      }
    },
  },
};
</script>

<style>
/* Hiding the headers breaks the column width specified, therefore this css is needed */
#dialog-table colgroup > :first-child {
  width: 40px;
  min-width: 40px
}

#dialog-body {
  padding-left: 10px;
  padding-right: 10px;
}
</style>
