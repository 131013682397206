<template>
  <v-container fluid>
    <v-card>
      <v-card-title class="pa-4">
        <v-icon left>mdi-email-open-outline</v-icon>
        Invitations
      </v-card-title>
      <v-data-table
        disable-pagination
        :hide-default-footer="true"
        :must-sort="true"
        :no-data-text="''"
        :headers="headers"
        :items="invitations"
        :loading="loading"
        :search="searchQuery"
      >
        <template v-slot:item.icon="{}">
          <v-icon></v-icon>
        </template>
        <template v-slot:item.icon="{ item }">
          <img :src="`${API_URL}/logo/${item.senderCompanyId}`" />
        </template>
        <template v-slot:item.name="{ item }">
          <div v-html="highlight(item.name, searchQuery)"></div>
        </template>

        <template v-slot:item.buttons="{ item }">
          <v-row>
            <v-spacer />
            <v-btn large color="primary" @click="accept(item)" class="ma-4" depressed :disabled="!isAdmin"
              >Accept</v-btn
            >
            <v-btn large color="error" @click="reject(item)" class="ma-4" depressed :disabled="!isAdmin">Reject</v-btn>
          </v-row>
        </template>
      </v-data-table>
    </v-card>
  </v-container>
</template>

<script>
import Auth0Mixin from "../Auth0Mixin";
import filterService from "@/services/filterService.js";
import searchUtilsService from "@/services/searchUtilsService.js";
import partyTypeList from "@/constants/partyTypeList";
import partyType from "@/constants/partyType";
import invitationService from "@/services/invitationService";
// import userService from "@/services/userService";
import { API_URL } from "@/env";
import { roles } from "@/services/constants";
import orgUnitService from "@/services/admin/orgUnitService";
import { mapGetters } from "vuex";
import partyService from "@/services/partyService";

/*function delay(time) {
  return new Promise(resolve => setTimeout(resolve, time));
}*/

export default {
  name: "InvitationList",
  mixins: [Auth0Mixin],
  props: {
    idByNotification: Number,
  },
  data() {
    return {
      headers: [
        { text: "", value: "icon" },
        { text: "Company", value: "companyName" },
        { text: "Company Address", value: "companyAddress" },
        { text: "Sender Email", value: "senderEmail" },
        { text: "Tax Number", value: "maskedTaxNumber" },
        { text: "", value: "buttons" },
      ],
      invitations: [],
      parties: [],
      searchQuery: "",
      loading: true,
      highlight: filterService.highlight,
      partyTypeList,
      API_URL,
      orgUnits: [],
      recalculatePartyList: false,
      recalculateExistingParty: false,
    };
  },

  computed: {
    ...mapGetters("auth0Store", ["selectedCompanyId"]),

    searchLabel: function() {
      return searchUtilsService.getLabel("Invitations", this.invitations);
    },
    isAdmin() {
      return this.$store.getters["auth0Store/userRoles"].includes(roles.Administrator);
    },
    filteredParties() {
      return index => {
        this.recalculatePartyList;
        return this.parties
          .filter(x => x.partyTypeId === this.invitations[index].partyTypeId && !(x.isInviteSent && !x.connectedCompanyId))
          .map(x => ({ text: x.name, value: x.number }));
      };
    },
    existingParty() {
      return index => {
        this.recalculateExistingParty;
        return !!(this.invitations[index]?.partyNumber && this.invitations[index]?.partyNumber.text);
      };
    },
  },
  methods: {
    selectPartyType(partyTypeId, item) {
      this.invitations[item.rowIndex].partyTypeId = partyTypeId;
      this.invitations[item.rowIndex].partyNumber = null;
      this.recalculatePartyList = !this.recalculatePartyList;
    },
    changePartyNumber(partyNumber, item) {
      this.invitations[item.rowIndex].partyNumber = partyNumber;
      this.recalculateExistingParty = !this.recalculateExistingParty;
    },
    async init() {
      await this.populateInvitations();
      if (this.idByNotification) {
        var inviteFromNotification = this.invitations.find(x => x.id === this.idByNotification);
        if (!inviteFromNotification) {
          let invitation = await invitationService.getInvitationById(this.idByNotification);
          
          let msg = "The invite has already been ";
          if (invitation == null) {
              msg += "actioned on";
          } else {
              if (invitation.accepted){
                  msg += 'accepted by user ';
              } else {
                  msg += 'rejected by user ';
              }
              msg += invitation.updateUser;
          }
          
          this.$root.$snackbar.message(msg);
        }
      }
    },

    async populateInvitations() {
      this.orgUnits = (await orgUnitService.getList(true)).items;
      this.invitations = await invitationService.getPending();

      let onlyOneOrgUnit = this.orgUnits.length === 1;

      this.parties = (
        await partyService.search(true, "", -1, "", {
          sortDesc: [false],
          page: 1,
          itemsPerPage: 10000,
          sortBy: "name",
        })
      ).items;

      this.invitations.forEach((inv, ind) => {
        inv.partyTypeId = partyType.PURCHASER;
        inv.orgUnits = this.orgUnits;
        inv.sourceParties = this.parties;
        inv.disablePartyType = (inv.senderPartyTypeOfReceiverCompany === partyType.VENDOR) ||
            (inv.senderPartyTypeOfReceiverCompany === partyType.OWNER) || (inv.senderPartyTypeOfReceiverCompany === partyType.OPERATOR);
        inv.orgUnitIds = onlyOneOrgUnit ? [this.orgUnits[0].id] : [];
        inv.rowIndex = ind;
      });
      this.loading = false;
    },

    async reject(invitation) {
      await invitationService.reject(invitation.id);
      this.populateInvitations();
      this.$store.dispatch("invitationStore/removePendingInvitation");
      this.$root.$snackbar.message("Invitation Rejected");
    },

    async accept(invitation) {
      this.$router.push({ name: "Invitation", params: { invitationId: invitation.id, invitation: invitation } });
    },
  },
};
</script>
